import React from 'react';
import OpeningHoursToday from "../../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../../Constants/Alerts";
import Alert from "../../../Components/Alert";
import Address from "../../../Components/Address";

class Producten extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Producten</title>
          <meta name="description" content="p het gebied van borduren hebben we een grote kast met dmc garen staan en daarnaast verschillende soorten stramien en borduurnaalden" />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-4">Fournituren en Stoffen in Kampen</h1>
              <section className="mb-4">
                <h2>Welkom bij onze winkel in Kampen</h2>
                <p>
                  Bent u op zoek naar hoogwaardige fournituren en stoffen in de omgeving van Kampen? Dan bent u bij ons aan het juiste adres! Wij bieden een indrukwekkend assortiment aan producten, perfect voor al uw creatieve en praktische naaiprojecten.
                </p>
              </section>

              <section className="mb-4">
                <h2>Fournituren</h2>
                <p>
                  Onze fournituren collectie bevat alles wat u nodig heeft om uw projecten tot leven te brengen. Denk hierbij aan:
                </p>
                <ul>
                  <li>Knopen en drukknopen</li>
                  <li>Biaisband en kant</li>
                  <li>Band, koord en elastiek</li>
                  <li>Ritsen en klittenband</li>
                  <li>Naaimachine-accessoires en onderhoudsproducten</li>
                </ul>
                <p>
                  Wij voeren de beste merken, zoals <strong>Prym</strong>, <strong>Gütermann allesnaaigaren</strong>, <strong>YKK</strong>, en meer!
                </p>
              </section>

              <section className="mb-4">
                <h2>Stoffen</h2>
                <p>
                  Onze stoffenafdeling biedt een breed scala aan materialen van hoge kwaliteit. Of u nu een nieuwe jas wilt maken, een jurk wilt naaien of uw interieur wilt verfraaien, wij hebben de perfecte stof voor u. Onze collectie bevat:
                </p>
                <ul>
                  <li>Katoen en linnen</li>
                  <li>Tricot en jersey</li>
                  <li>Satijn en zijde</li>
                  <li>Wol, fleece en flanel</li>
                </ul>
                <p>
                  Daarnaast helpen wij u graag met advies over welke stoffen geschikt zijn voor uw project.
                </p>
              </section>

              <section className="mb-4">
                <h2>Waarom kiezen voor ons in Kampen?</h2>
                <ul>
                  <li>Uitstekende kwaliteit en service</li>
                  <li>Ruim assortiment aan fournituren en stoffen</li>
                  <li>Deskundig advies voor al uw naaiprojecten</li>
                  <li>Betaalbare prijzen</li>
                </ul>
                <p>
                  Kom langs in onze winkel in IJsselmuiden, gemeente Kampen en ontdek alles wat wij te bieden hebben. Ons team staat voor u klaar om u te helpen!
                </p>
              </section>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Producten;
