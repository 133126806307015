import React from 'react';
import logo from "../../Assets/logo.svg";
import {NavLink} from "react-router-dom";
import {navLinks} from "../../Constants/Menu";

class Header extends React.Component {
  render() {
    const toggleMenu = e => {
      const trigger = e.target;
      const menu = document.getElementById('main-menu');
      const isMenuTrigger = trigger.classList.contains('menu-trigger');

      if (isMenuTrigger && !trigger.classList.contains('active')) {
        trigger.classList.add('active');
        menu.classList.add('show');
      } else if (isMenuTrigger && trigger.classList.contains('active')) {
        menu.classList.remove('show');
        trigger.classList.remove('active');
      } else {
        const menuTrigger = document.getElementsByClassName('menu-trigger')[0];
        menuTrigger.classList.remove('active');
        menu.classList.remove('show');
      }
    }

    return (
      <>
        <div className="container py-2 mb-3">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo van de Magneet" width="350"/>
          </a>
        </div>
        <header className="container bg-primary rounded shadow-sm">
          <nav className="navbar navbar-expand-lg mb-4">

            <button
              className="d-lg-none ms-auto menu-trigger float-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#main-menu"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleMenu}
            />
            <div className="collapse navbar-collapse" id="main-menu">
              <ul className="navbar-nav">
                {navLinks.map((link, index) => (
                  <li key={index} className="nav-item">
                    <NavLink
                      exact
                      activeClassName="active"
                      className="text-light nav-link px-3 rounded"
                      to={link.path}
                      onClick={toggleMenu}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </header>
      </>
    );
  }
}

export default Header;
