import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import HeaderImage from "../../Components/HeaderImage";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Home extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Kledingreparatie en Fournituren</title>
          <meta name="description" content="Verkoop van garen, ritsen, knopen, lint, Botter IJsselmuiden wol, sokkenwol, kantjes, bandjes de regio Kampen." />
        </Helmet>

        <div className="rounded">
          <HeaderImage />
        </div>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3"> Handwerkt u naar het zin! De Magneet. Voor naalden, stoffen, wol en alles er tussen in.</h1>
              <p>
                Welkom bij De Magneet - dé winkel in IJsselmuiden voor kledingreparatie, stoffen, fournituren, brei en haakbenodigdheden.
                U vindt ons aan de Dorpsweg 56 in IJsselmuiden, een centrale locatie voor klanten uit de regio Kampen, Zwolle, Genemuiden en Emmeloord.
              </p>
              <p>Kom gezellig langs in onze stoffen- en wolwinkel in IJsselmuiden of kijk rond op onze website om het assortiment te ontdekken!</p>
              <p>Parkeren kan bij ons voor de deur en u kunt zowel contant betalen als pinnen.</p>
              <p>Heeft u vragen? Neem gerust contact met ons op via telefoon of e-mail.</p>
              <p>Blijf op de hoogte van onze openingstijden, aanbiedingen en nieuwe producten via <a href="https://www.facebook.com/Aafke-De-magneet-522590124583273/" target="_blank" rel="noreferrer" title="Facebookpagina de Magneet">Facebook</a> en <a href="https://instagram.com/demagneet" target="_blank" rel="noreferrer" title="Instagrampagina de Magneet">Instagram</a>. Volg ons daar!</p>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Home;
