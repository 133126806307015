import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Stoffen extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Stoffen</title>
          <meta name="description" content="Wij hebben een uitgebreid assortiment aan stoffen. Ontdek onze diverse collectie met een ruime keuze aan materialen en kleuren voor al uw naaiprojecten." />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">Stoffen</h1>
              <p>
                Wij zijn bezig met het opbouwen van een prachtige collectie modestoffen! Op dit moment hebben we een kleine, zorgvuldig geselecteerde collectie in de winkel. Dit breiden we dit stap voor stap verder uit.
              </p>
              <p>Bij stoffen horen natuurlijk ook patronen! Wij hebben onder andere de Poppy/Pommé, de Qjutie en patronen van Super Nova in onze voorraad.</p>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Stoffen;
